<template>
  <article class="content-section section-03">
    <header class="section-header">
      <h4 class="title">{{ title }}</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">참가연도</span></th>
          <th><span class="th-title">과정명</span></th>
<!--          <th><span class="th-title">수료여부</span></th>-->
          <th><span class="th-title">취득점수</span></th>
          <th v-if="divCd === 'S'"><span class="th-title">응시구분</span></th>
          <th><span class="th-title">비고</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="items.length === 0">
          <td :colspan="(divCd === 'S') ? 5 : 4" style="text-align: center !important;"><span class="td-text">-</span></td>
        </tr>

        <tr v-for="(item, index) in items" :key="index" :class="{'kb-line-through': isDashLine}">
          <td><span class="td-text">{{ item.ended }}</span></td>
          <td><span class="td-text">{{ item.subjnm }}</span></td>
<!--          <td><span class="td-text">{{ item.grayn == 'Y' ? '수료' : '미수료' }}</span></td>-->
          <td><span class="td-text">{{ divCd === 'O' ? 100 : item.paccept }}점</span></td>
          <td v-if="divCd === 'S'"><span class="td-text">{{ getSubCategory(item.subjnm) }}</span></td>
          <td><span class="td-text">{{ item.reflectTarget }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </article>
</template>
<script>

import {capabilityBeginTableSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityBeginTable",
  components: {},
  props: {
    divCd: String,
    items: Array
  },
  setup: capabilityBeginTableSetup
}
</script>
