<template>
  <article class="content-section section-03">
    <header class="section-header">
      <h4 class="title">기타 보유 등급별 자격증</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col style="width:320px">
          <col>
          <col>
          <col>
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">자격증명</span></th>
          <th><span class="th-title">등급</span></th>
          <th><span class="th-title">점수</span></th>
          <th><span class="th-title">취득일자</span></th>
          <th><span class="th-title">비고</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="etcItems.length === 0">
          <td colspan="5" style="text-align: center !important;"><span class="td-text">-</span></td>
        </tr>

        <tr v-for="(item, index) in etcItems" :key="index">
          <td><span class="td-text">{{ item.lcsnNm }}</span></td>
          <td><span class="td-text">{{ item.grd1Cd }}</span></td>
          <td><span class="td-text">{{ result.ficjkgup.indexOf('L1') > -1 || result.ficjkgup.indexOf('L2') > -1 ? item.lcsnScore : '대상아님' }}</span></td>
          <td><span class="td-text">{{ item.passAnnonYmd }}</span></td>
          <td><span class="td-text">-</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </article>
</template>
<script>

export default {
  name: "CapabilityEtcLicense",
  components: {},
  props: {
    result: Object,
    etcItems: Array
  },
}
</script>
