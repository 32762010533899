<template>
  <article class="content-section section-02">
    <header class="section-header">
      <h4 class="title">영업점 상품판매 필수 자격증</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">자격증명</span></th>
          <th><span class="th-title">점수</span></th>
          <th><span class="th-title">취득일자</span></th>
          <th><span class="th-title">비고</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><span class="td-text">펀드투자권유자문인력</span></td>
          <td><span class="td-text">{{ salesMeta.fundDate ? '1' : '-' }}</span></td>
          <td><span class="td-text">{{ salesMeta.fundDate ? salesMeta.fundDate : '-' }}</span></td>
<!--          <td><span class="td-text">{{ salesMeta.fundDate ? 'Y' : 'N' }}</span></td>-->
          <td>
            <i v-if="salesMeta.fundDate" class="icon-o"></i>
            <i v-else class="icon-x"></i>
          </td>
        </tr>
        <tr>
          <td><span class="td-text">파생상품투자권유자문인력</span></td>
          <td><span class="td-text">{{ salesMeta.derivation ? '1' : '-' }}</span></td>
          <td><span class="td-text">{{ salesMeta.derivation ? salesMeta.derivation : '-' }}</span></td>
          <td>
            <i v-if="salesMeta.derivation" class="icon-o"></i>
            <i v-else class="icon-x"></i>
          </td>
        </tr>
        <tr>
          <td><span class="td-text">생명보험</span></td>
          <td rowspan="4" style="vertical-align: middle !important;"><span class="td-text">{{isRegFourLicense ? '1' : '-'}}</span></td>
          <td><span class="td-text">{{ salesMeta.lifeInsurance ? salesMeta.lifeInsurance : '-' }}</span></td>
          <td>
            <i v-if="salesMeta.lifeInsurance" class="icon-o"></i>
            <i v-else class="icon-x"></i>
          </td>
        </tr>
        <tr>
          <td><span class="td-text">손해보험</span></td>
          <td><span class="td-text">{{ salesMeta.generalInsurance ? salesMeta.generalInsurance : '-' }}</span></td>
          <td>
            <i v-if="salesMeta.generalInsurance" class="icon-o"></i>
            <i v-else class="icon-x"></i>
          </td>
        </tr>

        <tr>
          <td><span class="td-text">제3보험</span></td>
          <td><span class="td-text">{{ salesMeta.thirdInsurance ? salesMeta.thirdInsurance : '-' }}</span></td>
          <td>
            <i v-if="salesMeta.thirdInsurance" class="icon-o"></i>
            <i v-else class="icon-x"></i>
          </td>
        </tr>

        <tr>
          <td><span class="td-text">변액보험</span></td>
          <td><span class="td-text">{{ salesMeta.variableInsurance ? salesMeta.variableInsurance : '-' }}</span></td>
          <td>
            <i v-if="salesMeta.variableInsurance" class="icon-o"></i>
            <i v-else class="icon-x"></i>
          </td>
        </tr>


        </tbody>
      </table>
    </div>
  </article>
</template>
<script>

import {computed} from 'vue';

export default {
  name: "CapabilitySalesLicense",
  components: {},
  props: {
    result: Object,
    salesMeta: Object
  },
  setup(props) {

    const isRegFourLicense = computed(() => props.salesMeta.lifeInsurance && props.salesMeta.generalInsurance && props.salesMeta.thirdInsurance && props.salesMeta.variableInsurance)

    return {
      isRegFourLicense
    }

  }
}
</script>
