<template>
  <div class="main-content min-component">
    <CapabilityLicenseStatus
        :result="result"
    />

    <CapabilitySalesLicense
       :result="result"
       :sales-meta="salesMeta"
    />

    <CapabilityEtcLicense
        :result="result"
        :etc-items="etcItems"
    />

  </div>
</template>
<script>

import CapabilityLicenseStatus from '@/components/capability/CapabilityLicenseStatus';
import CapabilitySalesLicense from '@/components/capability/CapabilitySalesLicense';
import CapabilityEtcLicense from '@/components/capability/CapabilityEtcLicense';
import {capabilityLicenseSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityLicense",
  components: {CapabilityEtcLicense, CapabilitySalesLicense, CapabilityLicenseStatus},
  props: {
    result: Object
  },
  setup: capabilityLicenseSetup
}
</script>
