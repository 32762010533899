<template>
  <div class="main-content min-component">
    <CapabilityCourseStatus
        :result="result"
        :elements="elements"
    />

    <CapabilityBeginTable
        v-if="elements.includes('S')"
        div-cd="S"
        :items="items.S"
    />

    <CapabilityBeginTable
        v-if="elements.includes('O')"
        div-cd="O"
        :items="items.O"
    />

    <CapabilityGradeTable
        v-if="elements.includes('R') || elements.includes('P')"
        :div-cds="elements"
        :items="gradeItems"
        :result="result"
        :disp-div="'alldisp'"
    />
<!--    <template v-for="(div, index) in divCourses" :key="index">-->


<!--      <CapabilityGradetable-->
<!--          v-else-if="div.kb-tableDivCd === 'g'"-->
<!--          :courseDivCds="div.courseDivCds"-->
<!--          :items="div.items"-->
<!--          :requirements="requirements"-->
<!--      />-->
<!--    </template>-->

    <article class="content-section section-03">
      <div class="related-content">
        <article class="content-group">
          <h5 class="title">입행 / 승격일 2010.12.24 기준 직무필수 과정 확대</h5>
          <p class="text">L3- 2개 &gt; 3개</p>
          <p class="text">L2 및 L1 - 3개 &gt; 4개</p>
        </article>
        <article class="content-group">
          <h5 class="title">경과조치 관련문서</h5>
          <p class="text"><a href="javascript:" class="link" @click="downloadFileDownMng(guideFile[0].fileKey)">문서보기</a>연수규정 및 역량개발평가지침 일부 개정(인재개발원(규정) 제 100호, 2011.02.07)</p>
          <p class="text"><a href="javascript:" class="link" @click="downloadFileDownMng(guideFile[1].fileKey)">문서보기</a>역량개발평가(연수원평가)특례 인정 및 주요 문의사항 통보(연수원 652, 2006.12.11)</p>
        </article>
        <article class="content-group">
          <h5 class="title">직급별 직무필수과정 관련문서</h5>
          <p class="text"><a href="javascript:" class="link" @click="downloadFileDownMng(guideFile[2].fileKey)">문서보기</a>연수규정 및 역량개발평가지침 일부 개정(HR부(인재개발)(규정) 제 627호, 2014.08.01)</p>
        </article>
      </div>
    </article>

  </div>
</template>
<script>

import CapabilityBeginTable from '@/components/capability/CapabilityBeginTable';
import CapabilityGradeTable from '@/components/capability/CapabilityGradeTable';
import CapabilityCourseStatus from '@/components/capability/CapabilityCourseStatus';
import {capabilityCreditSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityCredit",
  components: {CapabilityCourseStatus, CapabilityGradeTable, CapabilityBeginTable},
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityCreditSetup
}
</script>
