<template>

  <div class="header-snb" style="margin-top: 24px;">
    <nav class="snb">
      <ul class="snb-list">
        <li v-for="(system, index) in systemRoutes" class="snb-item" :key="index">
          <a href="javascript:" class="snb-link" :class="{'is-active': index === currentIndex}" @click="currentIndex = index"><span class="snb-text">{{ system }}</span></a>
        </li>
      </ul>
    </nav>
  </div>

  <CapabilitySystemTrain
      v-if="currentIndex === 0"
  />
<!--  <CapabilitySystemScore-->
<!--      v-else-if="currentIndex === 1"-->
<!--  />-->
</template>
<script>

import CapabilitySystemTrain from '@/components/capability/CapabilitySystemTrain';
// import CapabilitySystemScore from '@/components/capability/CapabilitySystemScore';
import {capabilitySystemSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilitySystem",
  components: {
    // CapabilitySystemScore,
    CapabilitySystemTrain},
  props: {},
  setup: capabilitySystemSetup
}
</script>
