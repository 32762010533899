<template>
  <div class="main-content min-component">
    <!-- content-section: 역량개발평가 설명 -->
    <article class="content-section section-01">
      <header class="section-header">
        <h4 class="title">■ 역량개발평가란?</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table><tbody><tr><td><span class="td-text">직무역량 강화를 위하여 은행에서 정한 직급별/CDP별 연수체계에 의거하여 이수한 직급별 필수과정 및 직무 필수과정 이수실적을 <br>
당해 직급기간에 누적하여 평가하는 것을 의미합니다.</span></td></tr></tbody></table></div></div>
    </article>

    <!-- content-section: 평가항목 및 배점 일반직원(L직급) -->
    <article class="content-section section-02">
      <header class="section-header">
        <h4 class="title">■ 평가항목 및 배점</h4>
      </header>
      <h5 class="text">① 일반직원 (L직급)</h5>
      <div class="kb-table kb-table-bordered-v2 mt-2">
        <table>
          <colgroup>
            <col style="width:300px">
            <col>
            <col>
            <col>
            <col>

          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title text-gold">구분</span></th>
            <th><span class="th-title text-gold">L0</span></th>
            <th><span class="th-title text-gold">L1</span></th>
            <th><span class="th-title text-gold">L2</span></th>
            <th><span class="th-title text-gold">L3</span></th>

          </tr>
          </thead>
          <tbody >
          <tr>
            <th><strong class="th-title text-gold">신임과정 <sup>주1)</sup></strong></th>
<!--            <td colspan="3"><span class="td-text">신임점 포장</span></td>-->
            <td><span class="td-text">-</span></td>
            <td><span class="td-text">-</span></td>
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">40점</span></td>
          </tr>
          <tr>
            <th><strong class="th-title text-gold">직무필수과정/승격예비과정 <sup>주2)</sup></strong></th>
            <!--            <td colspan="3"><span class="td-text">신임점 포장</span></td>-->
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
          </tr>
          <tr>
            <th><strong class="th-title text-gold">KB은행실무종합과정 <sup>주3)</sup></strong></th>
            <!--            <td colspan="3"><span class="td-text">신임점 포장</span></td>-->
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">-</span></td>
            <td><span class="td-text">-</span></td>
          </tr>
          <tr>
            <th><strong class="th-title text-gold">계</strong></th>
            <!--            <td colspan="3"><span class="td-text">신임점 포장</span></td>-->
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="kb-table-bottom kb-table-bottom-divider">
        <p class="text text-muted">주1) 신임과정은 이수자에 대하여 40점을 부여함</p>
        <p class="text text-muted">주2) 직급별 이수해야 하는 직무 필수과정과 승격예비과정을 모두 수료한 후 각 취득 점수의 산술평균점수를 100분 비율로 환산하여 배점에 곱한 점수를 부여함</p>
        <p class="text text-muted">주3) 당해 직급의 상위 성적 2회분의 평균 성적을 100분 비율로 환산하여 배점에 곱한 점수를 부여함 (단, 2회 미만 응시자의 경우 1회분의 성적을 적용)</p>
      </div>
    </article>

    <!-- content-section:평가항목 및 배점 일반직원(S직급) -->
    <article class="content-section section-03">
      <header class="section-header">
        <h4 class="title"></h4>
      </header>
      <h5 class="text">② 전문직원 (S직급)</h5>
      <div class="kb-table kb-table-bordered-v2 mt-2">
        <table>
          <colgroup>
            <col>
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title text-gold">구분</span></th>
            <th><span class="th-title text-gold">S1</span></th>
            <th><span class="th-title text-gold">S2</span></th>
            <th><span class="th-title text-gold">S3</span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th><strong class="th-title text-gold">신임과정</strong></th>
            <td><span class="td-text">-</span></td>
            <td><span class="td-text">40점</span></td>
            <td><span class="td-text">40점</span></td>
          </tr>
          <tr>
            <th><strong class="th-title text-gold">승격예비과정</strong></th>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">60점</span></td>
            <td><span class="td-text">60점</span></td>
          </tr>

          <tr>
            <th><strong class="th-title text-gold">계</strong></th>
            <!--            <td colspan="3"><span class="td-text">신임점 포장</span></td>-->
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
            <td><span class="td-text">100점</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>


    <!-- content-section: 자격증 가점 내용 -->
    <article class="content-section section-04">
      <header class="section-header">
        <h4 class="title">■ 자격증 가점</h4>
      </header>
      <p class="text">직원의 역량향상을 위해 보유 자격증에 대해 다음과 같이 가점을 부여합니다.</p>
      <div class="kb-table kb-table-bordered-v2 mt-4">
          <table>
            <colgroup>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title text-gold">가점 대상직급</span></th>
              <th><span class="th-title text-gold">가점</span></th>
              <th colspan="2"><span class="td-text text-gold">평가항목</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th><strong class="th-title text-gold">L1</strong></th>
              <td style="vertical-align: inherit;"><span class="td-text ">최대 10점</span></td>
              <td style="vertical-align: inherit;"><span class="td-text ">상품판매 필수자격증<br>각 1점</span></td>
              <td style="vertical-align: inherit;"><span class="td-text ">기타 보유 자격증<br>등급별 가점부여<br>(최대 7점)</span></td>
            </tr>
            <tr>
              <th><strong class="th-title text-gold">L2</strong></th>
              <td style="vertical-align: inherit;"><span class="td-text">최대 5점</span></td>
              <td style="vertical-align: inherit;"><span class="td-text">상품판매 필수자격증<br>각 1점</span></td>
              <td style="vertical-align: inherit;"><span class="td-text">기타 보유 자격증<br>등급별 가점부여<br>(최대 5점)</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      <div class="kb-table-bottom kb-table-bottom-divider">
        <p class="text text-muted">▶ 상품판매 필수자격증 :  펀드투자권유자문인력, 파생상품투자권유자문인력, 보험 4종(생명,손해,제3,변액보험판매관리사 4종 모두 보유시)</p>
        <p class="text text-muted">▶ 기타 보유 등급별 자격증 :  A등급(5), B등급(4), C등급(3), D등급(2), E등급(1)</p>
        <p class="text text-muted"> &nbsp;&nbsp;&nbsp;&nbsp; ※ 자격증별 등급 부여 기준은 【연수규정 > [별표4] ‘자격증별 마일리지 부여기준’】에 의함</p>
      </div>
    </article>
  </div>

</template>
<script>

export default {
  name: "CapabilitySystemTrain",
}
</script>
