<template>
  <article class="content-section section-03">
    <header class="section-header">
      <h4 class="title">자격증취득 가점현황</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">구분</span></th>
          <th><span class="th-title">필수 자격증 취득점수</span></th>
          <th><span class="th-title">기타 자격증 취득점수</span></th>
          <th><span class="th-title">자격증 취득가점</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><span class="td-text">평가점수</span></td>
          <td><span class="td-text">{{ maxLicenseScore > 0 ? `${result.addscoreSales}점` : '대상아님' }}</span></td>
          <td><span class="td-text">{{ maxLicenseScore > 0 ? `${result.addscoreEtc}점` : '대상아님' }}</span></td>
          <td><span class="td-text">{{ maxLicenseScore > 0 ? `${result.addscoreLic}점 (최대가점:${maxLicenseScore}점)` : '대상아님' }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showMeta" class="related-table">
      <div class="kb-table-column column-lft">
        <div class="cell-box box-title">
          <h5 class="title">필수이수학점 (100점)</h5>
          <p class="text">직급별 필수과정(신임과정, 승격예비과정) 및 직무필수과정 이수실적 평가</p>
        </div>
        <div class="cell-box box-ex">
          <div class="ex-content">
            <span class="text">40점</span>
            <span class="text">(신임과정 수료 시 <sup>주1</sup> )</span>
          </div>
          <div class="ex-content ex-sum">
            <span class="text">60점</span>
          </div>
          <div class="ex-content">
            <span class="text text-line">(승격예비・직무필수과정 취득성적 <sup>주2</sup>)</span>
            <span class="text">100</span>
          </div>
        </div>
        <div class="cell-box box-content">
          <div class="content" data-type="주1 -">
            <p class="text">L2/L3 직원 신임과정 수료시 40점 부여</p>
            <p class="text">L0/L1 직원은 당해 직급의 KB은행실무종합과정(舊직원실무평가) 상위 성적 2회분의 평균 성적을 100분 비율로 환산하여 배점에 곱한 점수를 부여함 <br>
              (단, 2회 미만 응시자의 경우 1회분의 성적을 적용)</p>
          </div>
          <div class="content" data-type="주2 -">
            <p class="text">직무필수과정의 취득성적과 승격예비과정(집합의 취득성적을 평균하여 산출)</p>
          </div>
        </div>
      </div>
      <div class="kb-table-column column-rgt">
        <div class="cell-box box-title">
          <h5 class="title">자격증 취득가점 (직급별 최대 10, 5점이내)</h5>
          <p class="text">상품판매 필수 자격증  <sup>주3)</sup> 및 기타 보유 등급별 자격증  <sup>주4)</sup></p>
        </div>
        <div class="cell-box box-ex">
          <div class="ex-content">
            <span class="text">L1 - 최대가점 10점 ・ L2 -최대가점 5점</span>
          </div>
        </div>
        <div class="cell-box box-content">
          <div class="content" data-type="주3 -">
            <p class="text">펀드투자권유자문인력, 파생상품투자권유자문인력, 보험 4종(생명,손해,제3,변액보험판매관리사 4종 모두 보유시) : 가점 각 1점</p>
          </div>
          <div class="content" data-type="주4 -">
            <p class="text">A등급(5), B등급(4), C등급/직무인증제 LevelⅡ(3), D등급/직무인증제 LevelⅠ(2), E등급(1) :  가점 L1 최대 7점 / L2 최대 5점</p>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>

import {capabilityLicenseStatusSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityLicenseStatus",
  props: {
    result: Object,
    showMeta: Boolean
  },
  setup: capabilityLicenseStatusSetup
}
</script>
