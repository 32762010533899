<template>
  <div class="header-tab">
    <nav class="kb-nav-round-tabs">
      <ul class="kb-nav-list">
        <li v-for="(menu, index) in menus" class="kb-nav-item" :key="index">
<!--          <router-link :to="currentName === 'MyCapability' ? {params: {view: menu.view}} : {name: 'MyCapability', params: {view: menu.view}}" class="kb-nav-link" :class="{'is-active': menu.view === currentView}">-->
          <router-link :to="currentName === 'MyAbilityView' ? {params: {view: menu.view}} : {name: 'MyAbilityView', params: {view: menu.view}}" class="kb-nav-link" :class="{'is-active': menu.view === currentView}">
            <span class="kb-nav-text">{{ menu.name }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>

import {capabilityRouteSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityRoute",
  setup: capabilityRouteSetup
}
</script>
