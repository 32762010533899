<template>
  <main class="kb-main" :id="capabilityId">
    <!-- main-header -->
    <lxp-main-header :show-title="readies.result" :show-breadcrumb="true"  :file-down-key="fileDownKey">
      <template v-slot:action>
        <CapabilityRoute v-if="readies.result"/>
      </template>
    </lxp-main-header>
    <!-- //main-header -->
    <!-- main-content -->
    <template v-if="readies.result">
      <CapabilityResult
          v-if="currentView === 'result'"
          :result="result"
          :elements="elements"
      />
      <CapabilityCredit
          v-else-if="currentView === 'credit'"
          :result="result"
          :elements="elements"
      />
      <CapabilityReqSubj
          v-else-if="currentView === 'reqsubj'"
          :result="result"
          :elements="elements"
      />

      <CapabilityLicense
          v-else-if="currentView === 'certificate'"
          :result="result"
          :elements="elements"
      />
      <CapabilitySystem
          v-else-if="currentView === 'system'"
      />
    </template>
    <!-- //main-content -->
  </main>

</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import CapabilityRoute from '@/components/capability/CapabilityRoute';
import CapabilityResult from '@/components/capability/CapabilityResult';
import CapabilityCredit from '@/components/capability/CapabilityCredit';
import CapabilityLicense from '@/components/capability/CapabilityLicense';
import CapabilitySystem from '@/components/capability/CapabilitySystem';
import CapabilityReqSubj from '@/components/capability/CapabilityReqSubj';
import {capabilitySetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: 'MyCapability',
  components: {
    CapabilitySystem,
    CapabilityLicense,
    CapabilityCredit,
    CapabilityResult,
    CapabilityRoute,
    LxpMainHeader,
    CapabilityReqSubj,
  },
  setup: capabilitySetup
};
</script>
