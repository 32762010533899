<template>
  <article class="content-section section-01">
    <header class="section-header">
      <h4 class="title">평가현황</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col>
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">구분</span></th>
          <th><span class="th-title">필수이수학점</span></th>
          <th><span class="th-title">자격증가점</span></th>
          <th><span class="th-title">계(100점 만점)</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><span class="td-text">평가점수</span></td>
          <td><span class="td-text">{{ courseScore }}</span></td>
          <td><span class="td-text">{{ licenseScore }}</span></td>
          <td><span class="td-text">{{ totalScore }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </article>
</template>
<script>

import {capabilityTotalStatusSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityTotalStatus",
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityTotalStatusSetup
}
</script>
