<template>
  <div class="main-content min-component">
    <!-- content-section:평가현황 -->
    <CapabilityTotalStatus
        :result="result"
        :elements="elements"
    />
    <!-- content-section:필수이수학점현황 -->
    <CapabilityCourseStatus
        :result="result"
        :elements="elements"
    />
    <!-- content-section:자격증취득 가점현황 -->
    <CapabilityLicenseStatus
        :result="result"
        :show-meta="true"
    />

    <!-- content-section:평가활용방안 -->
    <article class="content-section section-04">
      <header class="section-header">
        <h4 class="title">평가활용방안</h4>
      </header>
      <div class="kb-table kb-table-bordered-v2">
        <table>
          <thead>
          <tr>
            <th><span class="th-title">일반직원 (L0~L3)</span></th>
            <th><span class="th-title">전문직원 (S1~S3)</span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><span class="td-text text-truncate">해당 직급에서의 필수이수학점, 자격증취득가점(L1,L2)을 합산하여 개인평가에 반영</span></td>
            <td><span class="td-text">해당 직급에서의 신임/승격예비과정 점수 개인평가에 반영</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>

    <!-- content-section:주) 자격증 최대 가점 경과기간 운영 -->
<!--    <article class="content-section section-05">-->
<!--      <header class="section-header">-->
<!--        <h4 class="title">주) 자격증 최대 가점 경과기간 운영</h4>-->
<!--      </header>-->
<!--      <div class="kb-table table-bordered-v2">-->
<!--        <table>-->
<!--          <colgroup>-->
<!--            <col>-->
<!--          </colgroup>-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th><span class="th-title">구분</span></th>-->
<!--            <th><span class="th-title">2019년</span></th>-->
<!--            <th><span class="th-title">2020년</span></th>-->
<!--            <th><span class="th-title">2021년</span></th>-->
<!--            <th><span class="th-title">2022년</span></th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td><span class="td-text">L1</span></td>-->
<!--            <td><span class="td-text">최대 2점</span></td>-->
<!--            <td><span class="td-text">최대 5점</span></td>-->
<!--            <td><span class="td-text">최대 7점</span></td>-->
<!--            <td><span class="td-text">최대 10점</span></td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td><span class="td-text">L2</span></td>-->
<!--            <td><span class="td-text">최대 1점</span></td>-->
<!--            <td><span class="td-text">최대 2점</span></td>-->
<!--            <td><span class="td-text">최대 3점</span></td>-->
<!--            <td><span class="td-text">최대 5점</span></td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </article>-->
  </div>
</template>
<script>

import CapabilityLicenseStatus from '@/components/capability/CapabilityLicenseStatus';
import CapabilityTotalStatus from '@/components/capability/CapabilityTotalStatus';
import CapabilityCourseStatus from '@/components/capability/CapabilityCourseStatus';

export default {
  name: "CapabilityResult",
  components: {CapabilityCourseStatus, CapabilityTotalStatus, CapabilityLicenseStatus},
  props: {
    result: Object,
    elements: Array
  },
  setup(){

    return {}
  }
}
</script>
