<template>
  <div class="main-content min-component">


    <div class="content-section-group" style="justify-content: center;">
      <article class="content-section section-lft">
        <div class="roundedbox-wrap" style="margin: 2em;">
          <!-- roundedbox -->
          <div v-for="(subj, index) in reqsubjGroup" style="width: 500px"   class="roundedbox" :key="index">
            <div class="roundedbox-top">
              <div class="top-row" style="justify-content: center; font-size: xx-large;">
                <h5 class="roundedbox-text">{{ subj.name }}</h5>
                  <div class="badge" :class="subj.maxCnt==subj.curCnt ? 'badge-primary' : 'badge-silver'">
                    <span class="badge-text">{{ subj.maxCnt==subj.curCnt ? '완료' : '미완료' }}</span>
                  </div>
              </div>
              <div class="top-row"  style="justify-content: center;font-size: xx-large;">
                  <h5 class="roundedbox-text">{{ subj.curCnt }}/ {{subj.maxCnt}}</h5>
              </div>
            </div>
            <div class="roundedbox-bottom">
              <a href="javascript:" class="kb-btn kb-btn-outline kb-btn-wide rounded-lg"
                 @click="showSubjPopup(subj.name)"><span class="text">교과목 보기</span></a>
            </div>
          </div>
        </div>
      </article>
      <!-- content-section:직무인증제 -->
    </div>

    <CapabilityGradeTable
        v-if="elements.includes('R')"
        :div-cds="elements"
        :items="gradeItems"
        :result="result"
        :disp-div="'reqsubjOnly'"
    />

    <CapabilityReqSubjPopup v-if="popupToggle" v-model:toggle="popupToggle" :div-key="divModalKey" :result="result" :reqDivSubjList="reqDivSubjList" />
<!--    <template v-for="(div, index) in divCourses" :key="index">-->


<!--      <CapabilityGradetable-->
<!--          v-else-if="div.kb-tableDivCd === 'g'"-->
<!--          :courseDivCds="div.courseDivCds"-->
<!--          :items="div.items"-->
<!--          :requirements="requirements"-->
<!--      />-->
<!--    </template>-->

    <article class="content-section section-03">
      <div class="related-content">
        <article class="content-group">
          <h5 class="title">입행 / 승격일 2010.12.24 기준 직무필수 과정 확대</h5>
          <p class="text">L3- 2개 &gt; 3개</p>
          <p class="text">L2 및 L1 - 3개 &gt; 4개</p>
        </article>
        <article class="content-group">
          <h5 class="title">경과조치 관련문서</h5>
          <p class="text"><a href="javascript:" class="link" @click="downloadFileDownMng(guideFile[0].fileKey)">문서보기</a>연수규정 및 역량개발평가지침 일부 개정(인재개발원(규정) 제 100호, 2011.02.07)</p>
          <p class="text"><a href="javascript:" class="link" @click="downloadFileDownMng(guideFile[1].fileKey)">문서보기</a>역량개발평가(연수원평가)특례 인정 및 주요 문의사항 통보(연수원 652, 2006.12.11)</p>
        </article>
        <article class="content-group">
          <h5 class="title">직급별 직무필수과정 관련문서</h5>
          <p class="text"><a href="javascript:" class="link" @click="downloadFileDownMng(guideFile[2].fileKey)">문서보기</a>연수규정 및 역량개발평가지침 일부 개정(인재개발부(규정) 제 627호, 2014.08.01)</p>
        </article>
      </div>
    </article>

  </div>
</template>
<script>

import CapabilityGradeTable from '@/components/capability/CapabilityGradeTable';
import {capabilityReqSubjSetup} from '@/assets/js/modules/capability/capability-setup';
import CapabilityReqSubjPopup from '@/components/capability/CapabilityReqSubjPopup';

export default {
  name: "CapabilityReqSubj",
  components: {CapabilityReqSubjPopup,  CapabilityGradeTable},
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityReqSubjSetup,


}
</script>
