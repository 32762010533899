<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-talentboard-o022">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">직무필수과정 [{{ divKey }}]부문 교과목 현황</h3>
          </header>
          <div class="popup-content">
            <!-- table-list-container -->
            <div class="kb-table-list-container">
              <!-- list-top -->
              <div class="list-top">
                <div class="top-column">
                </div>
                <div class="top-column">
<!--                  <strong class="total">총 {{ targetAbilities.length }}명</strong>-->
<!--                  <a href="javascript:" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="downloadExcel"><i class="icon-download"></i><span class="text">엑셀다운로드</span></a>-->
                </div>
              </div>
              <nav class="list-tabs">
                <ul class="tab-list">
                  <li v-for="(tab, index) in view.tabs" class="tab-item" :class="{'is-active': view.current === tab.key}" :key="index">
                    <a href="javascript:" class="tab" @click="view.current = tab.key">{{ tab.name }}</a>
                  </li>
                </ul>
              </nav>

              <div class="kb-table kb-table-padding kb-table-border">
                <table>
                  <colgroup>
                    <col style="width:250px">
                    <col style="width:140px">
                    <col style="width:100px">
                    <col>
                    <col>
                  </colgroup>
                  <thead>
                  <tr>
                    <th class="text-center cell-border"><span class="th-title">분야</span></th>
                    <th class="text-center cell-border"><span class="th-title">주관처</span></th>
                    <th class="text-center cell-border"><span class="th-title">매체</span></th>
                    <th class="text-center cell-border"><span class="th-title">과정명</span></th>
                    <th class="text-center cell-border"><span class="th-title">수강이력여부</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(item, index) in renderItems" :key="index">
                    <tr>
                      <td class="text-center"><span class="td-text">{{item.courseDivNm}}</span></td>
                      <td class="text-center"><span class="td-text">{{item.orgnzNm }}</span></td>
                      <td class="text-center"><span class="td-text">{{item.mediaCateNm.split(">")[0] }}</span></td>
                      <td class="text-center"> <router-link :to="{name: 'ApplyTrain', query: {crseMstSn: item.crseMstSn}}" class="td-link td-link-line"><span class="td-text">{{item.crseNm}}</span></router-link></td>
                      <td class="text-center"><span class="td-text">{{item.memo }}</span>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>
<script>

import {capabilityReqSubjPopupSetup} from "@/assets/js/modules/capability/capability-setup";

export default {
  name: "CapabilityReqSubjPopup",
  components: {},
  props: {
    toggle: Boolean,
    divKey: String,
    result: Object,
    reqDivSubjList: Object,
  },
  emits: ['update:toggle'],
  setup: capabilityReqSubjPopupSetup
}
</script>
