<template>
  <article class="content-section section-02">
    <header class="section-header">
      <h4 class="title">필수이수학점현황</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col style="width:220px">
          <col>
          <col style="width:368px">
          <col style="width:241px">
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">구분</span></th>
          <th><span class="th-title">{{ requirementText }}</span></th>
          <th><span class="th-title">승격예비 ・ 직무필수과정 <sup>주1)</sup></span></th>
          <th><span class="th-title">계(100점 만점)</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><span class="td-text">평가점수</span></td>
          <td>
            <span class="td-text">{{ beginScore }}</span>
            <div v-if="isFoundation" class="td-cell">
<!--              <p class="text text-muted">단, L0/L1 은 KB은행실무종합과정 (舊직원실무평가) 성적 상위 2회분 평균성적으로 신임과정 대체 평가</p>-->
              <p class="text text-muted">L0/L1 은 KB은행실무종합과정 (舊직원실무평가) 성적 상위 2회분 평균성적으로 평가</p>
            </div>
          </td>
          <td>
            <span class="td-text">{{ gradeScore }}</span>
            <div class="td-cell">
              <div v-if="isRequirement" class="label" :class="{'is-active' : result.pilsupassCnt !== 0 && result.pilsupassCnt >= result.jikmuReqCountDefault}"><span class="label-text">직무필수과정 {{ result.pilsupassCnt }}/{{ result.jikmuReqCountDefault }} {{result.pilsupassCnt >= result.jikmuReqCountDefault ? '수료' : '미수료'}}</span></div>
              <div v-if="result.preclassname" class="label" :class="{'is-active' : (result.jkgupGradeBookingId  > 0 || result.jkgupGradeLrnMapngRsltSumupSn > 0)}"><span class="label-text">예비과정({{ result.preclassname }})</span></div>
            </div>
          </td>
          <td><span class="td-text">{{ totalScore }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="kb-table-bottom kb-table-bottom-divider">
      <p class="text text-muted">주1 - 직무필수과정의 취득성적과 승격예비과정 종합평가의 취득성적을 평균하여 산출함</p>
    </div>
  </article>
</template>
<script>

import {capabilityCourseStatusSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityCourseStatus",
  props: {
    result: Object,
    elements: Array
  },
  setup: capabilityCourseStatusSetup
}
</script>
